<template slot-scope="scope">
	<div class="login" :style="{height: viewHeight}">
			<div class="login-logo">
				<img class="logo" src="../../assets/logo/logo1024.png" fit="fill" />
				<div>欢迎使用贯鱼超级店</div>
			</div>
			<div class="concent">
				<div class="title" v-if="showM != 3">
					<text :class="showM == 1 ? 'font' : ''" @click="switchLogin(1)">手机号登录</text>
					<text :class="showM == 2 ? 'font' : ''" @click="switchLogin(2)">账号登录</text>
				</div>
				<div v-if="showM == 1">
					<div class="input" >
						<div class="mobile">
							<div class="img">
								<img src="../../assets/login/mobile.png">
							</div>
							<el-input class="mobile-input" type='tel' maxlength="12" v-model='loginMobile.mobile' placeholder="手机号"></el-input>
						</div>
					</div>
					<div class="input">
						<div class="mobile code">
							<div class="img">
								<img src="../../assets/login/code.png">
							</div>
							<el-input class="mobile-input code-input" type='text' v-model='loginMobile.yzm' placeholder="验证码"></el-input>
							<div :class="showdate == 1 ? 'gain' : 'gain click-color'" @click="gainCode()"><text>{{gainCodeVal}}</text></div>
						</div>
					</div>
				</div>
				<div v-if="showM == 2">
					<div class="input" >
						<div class="mobile">
							<div class="img">
								<img src="../../assets/login/account.png">
							</div>
							<el-input class="mobile-input" type='tel' maxlength="12" v-model='loginAccount.mobile' placeholder="手机号/账号"></el-input>
						</div>
					</div>
					<div class="input">
						<div class="mobile code">
							<div class="img">
								<img src="../../assets/login/password.png">
							</div>
							<el-input class="mobile-input" type='password' maxlength="20" v-model='loginAccount.password' placeholder="密码" show-password 
							:onkeyup="(function() {loginAccount.password = loginAccount.password.replace(/\s+/g,'')})()"></el-input>
						</div>
					</div>
				</div>
				<div class="submit" @click="confirm()" v-if="showM != 3">
					<img src="../../assets/login/buttom.png" alt="">
				</div>
				<!-- <div class="other-login" @click="wxchat()" v-if="showM != 3">
					<div class="line"></div>其他登录方式<div class="line"></div><br/>
					<div class="wx-img">
						<img src="../../assets/login/wx.png" alt="">
					</div>
				</div>
				<div class="wx-login" v-show="showM == 3">
					<div class="title">扫码登录</div><br/>
					<div>请使用微信二维码登录</div>
					<div id="qrCode" style="width: 200px;height: 200px;"></div>
				</div> -->
			</div>
			
	</div>
</template>


<script>
import $ from '../../js/jquery-3.5.1.min';
import { ElMessage } from 'element-plus';
// import axios from 'axios';
import md5 from 'js-md5'
export default {
	// 需要在页面head中先引入如下 JS 文件（支持https）：
	head: {
			script: [
				{src: 'http://res.wx.qq.com/connect/zh_CN/htmledition/js/wxLogin.js'}
			]
	},
	data() {
		//这里存放数据
		return {
			loginMobile: {
				mobile: '',
				yzm: '',
				source: 3
			},
			loginAccount: {
				mobile: '',
				password: '',
        version: 1
			},
			loginAccount1: {
				mobile: '',
				password: '',
        version: 1
			},
			showM: 1,
			gainCodeVal: '获取验证码',
			showdate: 1,
			time: 60,
			onkey: 0
		};
	},
	//方法集合
	methods: {
		keyDown(e){
			//如果是回车则执行登录方法
      if(e.keyCode == 13){
				window.removeEventListener('keydown',this.keyDown,false);
				this.confirm();
      }
    },
		confirm() {
			let that = this;
			console.log(that.loginMobile,that.loginAccount,that.showM);
			let data,loginUrl,url;
			if (that.showM == 1) {
				if(that.loginMobile.mobile == '') {
					ElMessage({
						message: '请填写正确的手机号',
						type: 'error',
					})
					setTimeout(()=> {
						window.addEventListener('keydown',this.keyDown);
					},2000)
					return
				}else if(that.loginMobile.yzm == '') {
					ElMessage({
						message: '请填写正确的验证码',
						type: 'error',
					})
					setTimeout(()=> {
						window.addEventListener('keydown',this.keyDown);
					},2000)
					return
				}
				url = '/store/sms_login';
				data = that.loginMobile
			}else {
				if(that.loginAccount.mobile == '') {
					ElMessage({
						message: '请填写正确的账号',
						type: 'error',
					})
					setTimeout(()=> {
						window.addEventListener('keydown',this.keyDown);
					},2000)
					return
				}else if(that.loginAccount.password == '') {
					ElMessage({
						message: '请填写正确的密码',
						type: 'error',
					})
					setTimeout(()=> {
						window.addEventListener('keydown',this.keyDown);
					},2000)
					return
				}
        // return
				url = '/store/login';
				//进行深拷贝
				//防止出现用户名输入错误后点击登录导致密码也被转换为加密后的数据
				data = JSON.parse(JSON.stringify(this.loginAccount));
				data.password = md5(data.password);
			}
			that.$request.post(url,data).then(res => {
        console.log(res);
				if (res.data.is_main == 0 && res.data.power.length == 0) {
					ElMessage({
						type: 'warning',
						message: '请联系管理员添加权限！！！'
					})
					return
				}
				sessionStorage.setItem('access_token', JSON.stringify(res.data.access_token));
        sessionStorage.setItem('refresh_token', JSON.stringify(res.data.refresh_token));
				sessionStorage.setItem('login', JSON.stringify(res.data));
				that.$store.commit('access_token', res.data.access_token)
        that.$store.commit('refresh_token', res.data.refresh_token)
				window.removeEventListener('keydown',that.keyDown,false);  // 销毁回车事件
				this.$router.push({name: 'index'})
        // console.log(that.$store.state.access_token,'-----------',that.$store.state.refresh_token);
      }).catch(error => {
        console.error(error);
				ElMessage({
					message: error.data.msg,
					type: 'error',
				})
				that.loginAccount.password = '';
				that.loginMobile.yzm == '';
				setTimeout(()=> {
					window.addEventListener('keydown',this.keyDown);
				},2000)
      })
			// this.$router.push({name: 'index'})
		},
		wxCreate() {
				var obj = new WxLogin({
					self_redirect:true,
					id:"qrCode", 
					appid: "", 
					scope: "", 
					redirect_uri: "",
					state: "",
					style: "black",
					href: ""
				});
		},
		wxchat() {
			this.showM = 3
			// 生成登录二维码
			this.wxCreate();
			// this.$request.get('/store/sms_login',{
      //   params:data   
      // }).then(res => {
      //   console.log(res);
			// 	this.$router.push({name: 'index'})
      // }).catch(error => {
      //   console.log(error);
      // })
		},
		// 切换登录方式
		switchLogin(e) {
			this.showM = e; 
		},
		// 获取验证码
		gainCode() {
			const my = /^1[3-9]\d{9}$/
			if (!my.test(this.loginMobile.mobile)) {
				ElMessage({
					message: '手机号错误，请重新输入',
					type: 'error',
				})
				return
			}
			this.$request.post('/store/send_app_sms',{
				mobile: this.loginMobile.mobile,
				type: '2'
      }).then(res => {
				console.log(res);
				if(this.showdate == 2) {
					ElMessage({
						message: `请${this.time}秒后重试`,
						type: 'warning',
					})
					return
				}
				this.showdate = 2
				this.gainCodeVal = `${this.time}秒后获取`
				let setTime = setInterval(()=> {
					this.time--;
					this.gainCodeVal = `${this.time}秒后获取`
					this.$forceUpdate
					if (this.time == 0) {
						clearInterval(setTime)
						this.showdate = 1
						this.time = 60
						this.gainCodeVal = '获取验证码'
					}
				},1000)
			}).catch(error => {
				console.log(error);
				
			})
		}
	},
	//监控data中的数据变化
	watch: {
		
	},
	//这里存放数据
	components: {

	},
	//计算属性 类似于data概念
	computed: {
		viewHeight() {
			let height = $(window).height()
			return `${height}px`
		}
	},
	//生命周期 - 创建完成（可以访问当前this实例）
	created() {
	},
	//生命周期 - 挂载完成（可以访问DOM元素）
	mounted() {
		$('#nav').addClass('navNone');
		//绑定事件
    window.addEventListener('keydown',this.keyDown);
	},
	destroyed(){
		// 销毁回车事件
    window.removeEventListener('keydown',this.keyDown,false);
  },
};
</script>

<style lang="scss">
	.login {
		background: url('../../assets/login/bg.png') no-repeat 0 0;
		background-attachment: fixed;
		background-size: 100% 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor :pointer;
		div {
			display: inline-block;
		}
		.login-logo {
			width: 35.625rem;
			height: 40.625rem;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			background-color: rgba(255, 213, 0, .2);
			// opacity: 0.2;
			.logo {
				width: 12.57rem;
				height: 12.57rem;
				display: block;
				border-radius: 50%;
			}
			div {
				padding-top: 4rem;
				font-weight: 700;
				font-size: 1.5rem;
				font-family: 'MicrosoftYaHei-Bold';
			}
		}
		.concent {
			background-color: #fff;
			width: 35.625rem;
			height: 40.625rem;
			padding-top: 3.5rem;
			box-sizing: border-box;
			.title .input {
				display: block;
			}
			.title {
				font-size: 1.5rem;
				.font {
					font-size: 1.875rem;
				}
			}
			.title :nth-child(1)  {
					margin-right: 8rem;
			}
			.mobile {
				width: 35.625rem;
    		height: 3.5rem;
				margin-top: 4.22rem;
				.img {
					background-color: rgba(239, 239, 239, 1);
					width: 3.82rem;
					height: 3.5rem;
					border: 1px solid rgba(221, 221, 221, 1);
					img {
						width: 1.82rem;
						height: 2.25rem;
						position: relative;
   					top: 0.6rem;
					}
				}
				.mobile-input {
					width: 60%;
					line-height: 3.5rem;
					position: relative;
					top: -0.25rem;
					input {
						width: 90% !important;
					}
					.el-input__suffix {
						height: 0;
					}
				}
				.el-input__wrapper {
					width: 100%;
					height: 3.65rem;
					border-radius: 0;
					box-shadow: none;
					border: 1px solid rgb(221, 221, 221);
					box-sizing: border-box;
				}
				.code-input{
					width: 40%;
					.el-input__wrapper {
						border-right: 0;
					}
				}
				.gain {
					height: 3.6rem;
					padding-right: 1rem;
					border: 1px solid rgb(221, 221, 221);
					box-sizing: border-box;
					position: relative;
					top: -0.1rem;
					line-height: 3.65rem;
					border-left: 0;
					color: rgba(31, 145, 236, 1);
					text {
						width: 6rem;
    				display: inline-block;
					}
				}
				.click-color {
					color: rgba(170, 170, 170, 1);
				}
			}
			.code {
				margin-top: 2rem;
			}
			.submit {
				img {
					height: 4.1rem;
					width: 26.88rem;
					margin-top: 4.5rem;
					margin-left: 1.5rem;
				}
			}
		}
	}
	.other-login {
		margin-top: 2rem;
		.line {
			border-top: 1px solid #333;
			width: 1.5rem;
			position: relative;
			top: -0.3rem;
		}
		.wx-img {
			margin-top: 3rem;
			img {
				width: 2.5rem;
				height: 2.5rem;
			}
		}
	}
	.wx-login :nth-child(3){
		text-align: center;
		width: 100%;
    margin: 2rem 0;
	}
	iframe {
    position: relative;
    left: -2.5rem;
	}
</style>
