// 提现组件
<template>
		<div>
			<el-dialog v-model="dialogForm" title="货款提现" @close="$parent.refresh(false,1)">
				<el-form :model="form">
					<el-form-item label="支付宝账号" :label-width="formLabelWidth">
						<span>{{aliInfo.ali_name}}</span>
					</el-form-item>
					<el-form-item label="提现金额" :label-width="formLabelWidth">
						<el-input v-model="form.money" :placeholder="money" autocomplete="off" />
					</el-form-item>
					<el-form-item label="备注" :label-width="formLabelWidth">
						<el-input v-model="form.remank" placeholder="备注内容" autocomplete="off" />
					</el-form-item>
				</el-form>
				<template #footer>
					<span class="dialog-footer">
						<el-button @click="dialogForm = false">取消</el-button>
						<el-button type="primary" @click="withdrawal">
							确定提现
						</el-button>
					</span>
				</template>
			</el-dialog>
		</div>
</template>

<script>
import { ElMessage } from 'element-plus';
export default {
		name: "Withdraw",
		props: {
			dialogForm: {
				type: Boolean,
				default: false
			},
			money: {
				type: String,
				default: ''
			},
			type: {
				type: Number,
				default: 0
			},
		},
		data() {
				//这里存放数据
				return {
        	formLabelWidth: '140px',
					form: {
						money: '',
						remank: ''
					},
					aliInfo: {
						ali_name: '',
						ali_account: ''
					}
				};
		},
		//方法集合
		methods: {
			// 获取支付宝信息
			getAliInfo() {
				let that = this;
				let store_id = JSON.parse(sessionStorage.getItem('storeInfo')).id;
				this.$request.get('/store/ali_info',{
					params:{
						store_id,
					} 
				}).then(res => {
					console.log(res.data);
					if (res.data == null) {
						that.$parent.refresh(false,1,'kong');
						return
					}
					this.aliInfo = res.data;
				}).catch(error => {
					console.log(error);
				})
			},
			 // 提现
			withdrawal() {
				let that = this;
				let store_id = JSON.parse(sessionStorage.getItem('storeInfo')).id;
				if (this.form.money == '') {
					ElMessage({
						type: 'error',
						message: '金额不能为空'
					})
					that.$parent.refresh(false,1);
					return
				}
				if (this.type == 1) {
					let data = {
						account_id: 1,
						money: that.form.money
					}
					this.$request.post('/agent/agent_withdrawal',data).then(res => {
						console.log(res);
						ElMessage({
							type: 'success',
							message: res.msg
						})
						that.form = {
							money: '',
							remank: ''
						}
						that.$parent.getListData();
						that.$parent.refresh(false,1);
					}).catch(error => {
						console.log(error);
						ElMessage({
							type: 'error',
							message: error.data.msg
						})
						that.form = {
							money: '',
							remank: ''
						}
						that.$parent.getListData();
						that.$parent.refresh(false,1);
					})
				}else {
					this.$request.get('/store/withdrawal',{
						params: {
							store_id,
							money: that.form.money
						} 
					}).then(res => {
						console.log(res);
						ElMessage({
							type: 'success',
							message: res.msg
						})
						that.form = {
							money: '',
							remank: ''
						}
						that.$parent.getListData();
						that.$parent.refresh(false,1);
					}).catch(error => {
						console.log(error);
						ElMessage({
							type: 'error',
							message: error.data.msg
						})
						that.form = {
							money: '',
							remank: ''
						}
						that.$parent.getListData();
						that.$parent.refresh(false,1);
					})
				}
				
			},
		},
		//这里存放数据
		components: {},
		//计算属性 类似于data概念
		computed: {},
		//生命周期 - 创建完成（可以访问当前this实例）
		created() {
			this.getAliInfo();
		},
		//生命周期 - 挂载完成（可以访问DOM元素）
		mounted() {},
		//监控data中的数据变化
		watch: {},
};
</script>

<style scoped lang="scss">

</style>
