import { createStore } from 'vuex'

export default createStore({
  state() {
    return {
      // gystore: `http://testgystore.guanyubuy.com`, //测试
      // JIMVersion: "debug_sd",  //新测试
			gystore: 'http://gystore.guanyubuy.com', //正式
      JIMVersion: "release",    //新正式
			// 极光
			// JIMAppkey: "b0cb5a6dfc1cf9f6b2a071a4",
			JIMToAppkey: "01d0021e31ab6b57ca63a4df",
			//极光是否初始化完成状态值
			JiGuangInitState: false,
      im_auth_payload: '',
      isGetSyncConversation: '',
      access_token: '',
      refresh_token: '',
      unread_msg_count: 0
    }
  },
  getters: {
    unread_msg_count(new_count, old) {
      console.log(new_count, old);
    }
  },
  mutations: {
    increment (state,payload) {
      state.im_auth_payload = payload 
    },
    setIsGetSyncConversation(state,e) {
      state.isGetSyncConversation = e
    },
    access_token(state,a) {
      console.log(a);
      state.access_token = a;
    },
    refresh_token(state,a) {
      console.log(a);
      state.refresh_token = a;
    },
    unread_msg_count(state, a) {
      console.log(a);
      state.unread_msg_count = a;
    }
  },
  actions: {
  },
  modules: {
  }
})
