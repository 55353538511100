import { createRouter, createWebHistory,  createWebHashHistory } from 'vue-router'
import login from '../views/login/login.vue'
// import Router from 'vue-router'

const globalRoutes=[
  {
    path: '/login',
    name: 'login',
    component: login
  },
];

const mainRoutes = {
    path: '/',
    component: () => import('../views/home/home.vue'),
    redirect: { name: "home" },
    children: [
      {
        path: '/',
        name: 'index',
        component: () => import('../views/pages/index/index.vue')
      },
      {
        path: '/talk',
        name: 'talk',
        component: () => import('../views/pages/talk/talk.vue')
      },
      {
        path: '/order',
        name: 'order',
        component: () => import('../views/pages/order/order.vue')
      },
      {
        path: '/allgoods',
        name: 'allgoods',
        component: () => import('../views/pages/allgoods/allgoods.vue')
      },
      {
        path: '/carousel',
        name: 'carousel',
        component: () => import('../views/pages/carousel/carousel.vue')
      },
      {
        path: '/exportList',
        name: 'exportList',
        component: () => import('../views/pages/order/exportList.vue')
      },
      {
        path: '/serviceList',
        name: 'serviceList',
        component: () => import('../views/pages/order/serviceList.vue')
      },
      {
        path: '/evaluate',
        name: 'evaluate',
        component: () => import('../views/pages/evaluate/evaluate.vue')
      },
      {
        path: '/bond',
        name: 'bond',
        component: () => import('../views/pages/bond/bond.vue')
      },
      {
        path: '/storeAssets',
        name: 'storeAssets',
        component: () => import('../views/pages/storeAssets/storeAssets.vue')
      },
      {
        path: '/dataCount',
        name: 'dataCount',
        component: () => import('../views/pages/dataCount/dataCount.vue')
      },
      {
        path: '/secondHand',
        name: 'secondHand',
        component: () => import('../views/pages/secondHand/secondHand.vue')
      },
      {
        path: '/commission',
        name: 'commission',
        component: () => import('../views/pages/commission/commission.vue')
      },
      {
        path: '/holdAll',
        name: 'holdAll',
        component: () => import('../views/pages/holdAll/holdAll.vue')
      },
      {
        path: '/payment',
        name: 'payment',
        component: () => import('../views/pages/payment/payment.vue')
      },
      {
        path: '/paymentInfo',
        name: 'paymentInfo',
        component: () => import('../views/pages/paymentInfo/paymentInfo.vue')
      },
      {
        path: '/toEntry',
        name: 'toEntry',
        component: () => import('../views/pages/toEntry/toEntry.vue')
      },
      {
        path: '/orderInfo',
        name: 'orderInfo',
        component: () => import('../views/pages/orderInfo/orderInfo.vue')
      },
      {
        path: '/serviceInfo',
        name: 'serviceInfo',
        component: () => import('../views/pages/serviceInfo/serviceInfo.vue')
      },
      {
        path: '/dynamic',
        name: 'dynamic',
        component: () => import('../views/pages/dynamic/dynamic.vue')
      },
      {
        path: '/storeSort',
        name: 'storeSort',
        component: () => import('../views/pages/storeSort/storeSort.vue')
      },
      {
        path: '/notice',
        name: 'notice',
        component: () => import('../views/pages/notice/notice.vue')
      },
      {
        path: '/storeSet',
        name: 'storeSet',
        component: () => import('../views/pages/storeSet/storeSet.vue')
      },
      {
        path: '/catagory',
        name: 'catagory',
        component: () => import('../views/pages/catagory/catagory.vue')
      },
      {
        path: '/freight',
        name: 'freight',
        component: () => import('../views/pages/freight/freight.vue')
      },
      {
        path: '/addGoods',
        name: 'addGoods',
        component: () => import('../views/pages/addGoods/addGoods.vue'),
        // meta: {
        //   keepAlive: true,
        //   cacheList: ['freight'] 
        // }
      },
      {
        path: '/storeAddress',
        name: 'storeAddress',
        component: () => import('../views/pages/storeAddress/storeAddress.vue')
      },
      {
        path: '/setPoster',
        name: 'setPoster',
        component: () => import('../views/pages/setPoster/setPoster.vue')
      },
      {
        path: '/shareRecord',
        name: 'shareRecord',
        component: () => import('../views/pages/shareRecord/shareRecord.vue')
      },
      {
        path: '/dataAdmin',
        name: 'dataAdmin',
        component: () => import('../views/pages/dataAdmin/dataAdmin.vue')
      },
      {
        path: '/dataGoodsList',
        name: 'dataGoodsList',
        component: () => import('../views/pages/dataGoodsList/dataGoodsList.vue')
      },
      {
        path: '/resource',
        name: 'resource',
        component: () => import('../views/pages/resource/resource.vue')
      },
      {
        path: '/drainageCases',
        name: 'drainageCases',
        component: () => import('../views/pages/drainageCases/drainageCases.vue')
      },
      {
        path: '/articleInfo',
        name: 'articleInfo',
        component: () => import('../views/pages/articleInfo/articleInfo.vue')
      },
      {
        path: '/videoCourse',
        name: 'videoCourse',
        component: () => import('../views/pages/videoCourse/videoCourse.vue')
      },
      {
        path: '/videoInfo',
        name: 'videoInfo',
        component: () => import('../views/pages/videoInfo/videoInfo.vue')
      },
      {
        path: '/agent',
        name: 'agent',
        component: () => import('../views/pages/agent/agent.vue')
      },
      {
        path: '/extend',
        name: 'extend',
        component: () => import('../views/pages/extend/extend.vue')
      },
      {
        path: '/orderExport',
        name: 'orderExport',
        component: () => import('../views/pages/orderExport/orderExport.vue')
      },
      {
        path: '/story',
        name: 'story',
        component: () => import('../views/pages/story/story.vue')
      },
      {
        path: '/teSeFee',
        name: 'teSeFee',
        component: () => import('../views/pages/teSeFee/teSeFee.vue')
      },
      {
        path: '/resourceOrder',
        name: 'resourceOrder',
        component: () => import('../views/pages/resourceOrder/resourceOrder.vue')
      },
      {
        path: '/consignment',
        name: 'consignment',
        component: () => import('../views/pages/consignment/consignment.vue')
      },
      {
        path: '/myResource',
        name: 'myResource',
        component: () => import('../views/pages/myResource/myResource.vue')
      },
      {
        path: '/resourceGoods',
        name: 'resourceGoods',
        component: () => import('../views/pages/resourceGoods/resourceGoods.vue')
      },
      {
        path: '/goodInfo',
        name: 'goodInfo',
        component: () => import('../views/pages/goodInfo/goodInfo.vue')
      },
      {
        path: '/reStoreGoods',
        name: 'reStoreGoods',
        component: () => import('../views/pages/reStoreGoods/reStoreGoods.vue')
      },
      {
        path: '/resourceSearch',
        name: 'resourceSearch',
        component: () => import('../views/pages/resourceSearch/resourceSearch.vue')
      },
      {
        path: '/myAgent',
        name: 'myAgent',
        component: () => import('../views/pages/myAgent/myAgent.vue')
      },
      {
        path: '/resourceOrderInfo',
        name: 'resourceOrderInfo',
        component: () => import('../views/pages/resourceOrderInfo/resourceOrderInfo.vue')
      },
      {
        path: '/agentStoreNum',
        name: 'agentStoreNum',
        component: () => import('../views/pages/agentStoreNum/agentStoreNum.vue')
      },
      {
        path: '/goodsInfo',
        name: 'goodsInfo',
        component: () => import('../views/pages/goodsInfo/goodsInfo.vue')
      },
      {
        path: '/goodSortList',
        name: 'goodSortList',
        component: () => import('../views/pages/goodSortList/goodSortList.vue')
      },
      {
        path: '/agentProfit',
        name: 'agentProfit',
        component: () => import('../views/pages/agentProfit/agentProfit.vue')
      },
      {
        path: '/agentProfitItem',
        name: 'agentProfitItem',
        component: () => import('../views/pages/agentProfitItem/agentProfitItem.vue')
      },
      {
        path: '/yePageSet',
        name: 'yePageSet',
        component: () => import('../views/pages/yePageSet/yePageSet.vue')
      },
      {
        path: '/communityNotice',
        name: 'communityNotice',
        component: () => import('../views/pages/communityNotice/communityNotice.vue')
      },
      {
        path: '/comNotice',
        name: 'comNotice',
        component: () => import('../views/pages/comNotice/comNotice.vue')
      },
      {
        path: '/readNum',
        name: 'readNum',
        component: () => import('../views/pages/readNum/readNum.vue')
      },
      {
        path: '/communityAmbitus',
        name: 'communityAmbitus',
        component: () => import('../views/pages/communityAmbitus/communityAmbitus.vue')
      },
      {
        path: '/communityEdit',
        name: 'communityEdit',
        component: () => import('../views/pages/communityEdit/communityEdit.vue')
      },
      {
        path: '/ambitusInfo',
        name: 'ambitusInfo',
        component: () => import('../views/pages/ambitusInfo/ambitusInfo.vue')
      },
      {
        path: '/feedback',
        name: 'feedback',
        component: () => import('../views/pages/feedback/feedback.vue')
      },
      {
        path: '/visitorTime',
        name: 'visitorTime',
        component: () => import('../views/pages/visitorTime/visitorTime.vue')
      },      {
        path: '/toolbox',
        name: 'toolbox',
        component: () => import('../views/pages/toolbox/toolbox.vue')
      },
      {
        path: '/toolboxMenage',
        name: 'toolboxMenage',
        component: () => import('../views/pages/toolbox/toolboxMenage.vue')
      },
      {
        path: '/purchaseTab',
        name: 'purchaseTab',
        component: () => import('../views/pages/toolbox/purchaseTab.vue')
      },
      {
        path: '/applet',
        name: 'applet',
        component: () => import('../views/pages/toolbox/applet.vue')
      },
      {
        path: '/member',
        name: 'member',
        component: () => import('../views/pages/member/member.vue')
      },
      {
        path: '/setMember',
        name: 'setMember',
        component: () => import('../views/pages/member/setMember.vue')
      },
      {
        path: '/memberTab',
        name: 'memberTab',
        component: () => import('../views/pages/member/memberTab.vue')
      },
      {
        path: '/memberSearch',
        name: 'memberSearch',
        component: () => import('../views/pages/member/memberSearch.vue')
      },
      {
        path: '/memberInfo',
        name: 'memberInfo',
        component: () => import('../views/pages/member/memberInfo.vue')
      },
      {
        path: '/freeCoupon',
        name: 'freeCoupon',
        component: () => import('../views/pages/coupon/freeCoupon.vue')
      },
      {
        path: '/subAccount',
        name: 'subAccount',
        component: () => import('../views/pages/subAccount/subAccount.vue')
      },
      {
        path: '/accountTab',
        name: 'accountTab',
        component: () => import('../views/pages/subAccount/accountTab.vue')
      },
      {
        path: 'postList',
        name: 'postList',
        component: () => import('../views/pages/subAccount/postList.vue')
      },
    ]
  }



const router = createRouter({
  history: createWebHashHistory(),
  routes: globalRoutes.concat(mainRoutes),
  
})

export default router
