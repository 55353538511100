<template>
  <div class="breadcrumb-tip">
    <div class="flex-start">
      <!-- <span class="current-position">当前位置：</span> -->
      <el-breadcrumb separator=">">
        <el-breadcrumb-item v-for="(item, index) in list" :key="index">
          <a @click.prevent="handleClick(item)">{{ item.text }}</a>
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
  </div>
</template>
 
<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    handleClick(item) {
      console.log(item);
      if (item.to && item.to.name) {
        this.$router.push({
          name: item.to.name,
          params: {
            ...item.to.params,
            ...item.to.meta,
          },
          query: {
            ...item.to.query,
            ...item.to.meta,
          },
        });
      }
      
    },
  },
  //计算属性 类似于data概念
  computed: {
    // width() {
    //   console.log(document.body.scrollWidth); //获取网页正文全文宽
    //   let width = document.body.scrollWidth - 365
    //   return `${width}px`
    // }
  },
};
</script>
<style scoped>
.flex-start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.el-breadcrumb {
    font-size: 1rem;
}
.breadcrumb-tip {
	background-color: #fff;
	border-radius: 0.5rem;
	padding: 1rem 1.5rem;
	font-weight: 700;
	margin-bottom: 0.5rem;
}
</style>