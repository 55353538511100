import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import request from './utils/request'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import jquery from "../src/js/jquery-3.5.1.min";
import JMessage from '@/components/JM/jmessage-sdk-web.2.6.0.min'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import Breadcrumb from "@/components/breadcrumb.vue"
import Table from "@/components/table.vue"
import Withdraw from "@/components/withdraw.vue"
import * as echarts from 'echarts'
import {transformTimestamp,getUploadToken} from './utils/utils.js';
import { ElConfigProvider } from 'element-plus'

const app = createApp(App);
app.config.globalProperties.$echarts = echarts;
app.config.globalProperties.$ = jquery;
app.config.globalProperties.$request = request;   //全局注册request，其他文件可通过this.$request使用分装好的request实例
app.config.globalProperties.$transformTimestamp = transformTimestamp;   //全局注册transformTimestamp，其他文件可通过this.$transformTimestamp使用封装好的实例
app.config.globalProperties.$getUploadToken = getUploadToken;   //全局注册getUploadToken，其他文件可通过this.$getUploadToken使用封装好的实例
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}


app.component(ElConfigProvider,[ElConfigProvider.name]) //全局组件绑定 button
app.component("Breadcrumb", Breadcrumb); //全局注册面包屑Breadcrumb组件
app.component("Table", Table); //全局注册表格Table组件
app.component("Withdraw", Withdraw); //全局注册提现Withdraw组件
app.use(store).use(router).use(ElementPlus).mount('#app')



// 极光聊天
const JIMVersion = store.state.JIMVersion;
console.log(JIMVersion);
//极光IM在线聊天配置
var JIM = new JMessage({
  // debug: true
});

//初始化
function JIMInit(auth_payload) {
  //首次进入app未获取auth_payload
  if (auth_payload == undefined && store.state.im_auth_payload == "") {
    console.log('//首次进入app未获取auth_payload');
    getAuthPayload()
    return
  }
  //缓存中有auth_payload
  if (auth_payload == undefined && store.state.im_auth_payload != '') {
    console.log('//缓存中有auth_payload', auth_payload);
    auth_payload = store.state.im_auth_payload
  }
  auth_payload.flag = 1
  // return new Promise((resolve, reject) => {
  console.log("极光auth_payload:----------------",store.state.im_auth_payload.flag, auth_payload.flag)
  //初始化
  JIM.init(auth_payload).onSuccess(function(data) {
    console.log("-----极光IM初始化完成-----", data)
    //登录
    console.log("-----极光发起登录-----")
    // const username = `${JIMVersion}_25` //测试,替换uid
    const username = `${JIMVersion}_${JSON.parse(sessionStorage.getItem("storeInfo")).id}`
    console.log(username);
    JIM.login({
      'username': username,
      'password': '123456'
    }).onSuccess(data => {
      // resolve(data)
      console.log("-----极光登录成功-----", data)
			let storeName = JSON.parse(sessionStorage.getItem("storeInfo")).name
      // 修改极光昵称
      JIM.updateSelfInfo({
         'nickname' : storeName,
       }).onSuccess(function(data) {
          console.log("修改名字",data);
           //data.code 返回码
           //data.message 描述
       }).onFail(function(data) {
           //同上
           console.log(data);
       });
      //  修改极光头像
      // let avatar = JSON.parse(sessionStorage.getItem("storeInfo")).logo
      // console.log(avatar);
      // let avatar = 'http://mdbwk2.cn/2022/08-11/wh38790652.png'
      // JIM.updateSelfAvatar({
      //  'avatar' : avatar
      // }).onSuccess(function(data) {
      //   console.log(data);
      //     //data.code 返回码
      //     //data.message 描述
      // }).onFail(function(data) {
      //     //同上
      //     console.log(data);
      // });
      store.state.JiGuangInitState = true
      //获取漫游消息
      getSyncConversation()
    }).onFail(data => {
      if (data.code == 880103) {
        //用户不存在，进行注册
        console.log("-----极光 用户不存在，进行注册-----")
        JIMRegister(username)
      } else {
        console.log(data)
      }
    })
  }).onFail(data => {
    if (data.code == 880106) {
      //签名过期，重新获取签名
      console.log("-----极光签名过期，重新获取签名-----")
      getAuthPayload()
    } else {
      console.log(data)
    }
  });
}

//获取签名
function getAuthPayload() {
    //获取签名
    console.log("-----极光开始获取签名-----")
		request.get("/store/get_jg_signature",{
    }).then(res => {
			store.commit('increment', res.data)
			console.log(res.data);
			JIMInit(res.data)
		}).catch(error => {
			console.error(error);
		})
}


//断线监听
JIM.onDisconnect(function () {
  console.log('断线监听');
  getAuthPayload()
});

//注册
function JIMRegister(username) {
  console.log("-----用户不存在，开始自动注册极光-----")
  JIM.register({
    'username': username,
    'password': '123456',
  }).onSuccess(function (data) {
    if (data.code == 0) {
      JIMInit()
      console.log("注册极光账户成功，重新调起初始化")
    }
  }).onFail(function (data) {
    console.log(data)
  });
}

//获取离线消息列表
function getSyncConversation() {
  //是否已经调过离线缓存消息了，如果调过了，就设个true
	store.state.isGetSyncConversation = true;
  console.log('main---');
  JIM.onSyncConversation(function (data) {
    console.log(data,"main.js/161");
    let sync_conversationNew = [];
    let sync_conversationServe = [];
    data.map((item,i)=>{
      if (item.from_username ==  "gyJgAdmin") {
        sync_conversationServe.push(item.msgs);
        JIM.resetUnreadCount({
          'username': "gyJgAdmin",
          "appkey": "01d0021e31ab6b57ca63a4df"
        });
        console.log('2222222222222222');
      }else {
        sync_conversationNew.push(item);
      }
    })
    // console.log(sync_conversationNew);
    // console.log(sync_conversationServe);
		sessionStorage.setItem('sync_conversation', JSON.stringify(sync_conversationNew));
		// sessionStorage.setItem('sync_conversationServe', JSON.stringify(sync_conversationServe));
  });
}


export  {
  JIM,
  JIMInit,
  getSyncConversation
}