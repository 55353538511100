<template>
	<el-card>
	<!-- 表格 -->
	 <el-table :data="tableData"	border	stripe	
	 style="width: 100%" 
	 :empty-text="empty" 
	 >
			<!--序号 -->
			<template>
				<el-table-column v-show="showIndex" type='index' width="60" label="序号" ></el-table-column>
			</template>
			
			<!-- 特殊处理列表 switch -->
		  <template v-for="(value,i) in tableLabel ">
				<!-- 特殊处理列表 操作，不会使用插槽  商品页操作 -->
				<!-- 商品管理部分 -->
				<el-table-column  v-if="value.type==='goodopt'" :key="i" :prop='value.prop'	:label='value.label'  :width="gotype != 'sellOut' ? 290 : 220">
					<!-- vue3里不适应slot-scope ,使用#default="scope"-->
					<template #default="scope">
							<el-button size="small" type='primary' v-if="scope.row.is_on_sale == 0" 	@click="handlePut(scope.row,scope.$index)">上架</el-button>
							<el-popconfirm
								confirm-button-text="确定"
								cancel-button-text="取消"
								icon="InfoFilled"
								icon-color="#626AEF"
								title="确定下架该商品?"
								@confirm="handleLower(scope.row,scope.$index)"
								@cancel="cancelEvent"
								v-if="scope.row.is_on_sale == 1"
							>
								<template #reference>
									<el-button size="small" type='primary'>下架</el-button>
								</template>
							</el-popconfirm>
							<!-- <el-button size="small" type='primary' v-if="scope.row.state != 3" 	@click="handleStock(scope.row.id,scope.$index)">改库存</el-button> -->
							<el-button size="small" type='primary' @click="goodsEdit(scope.row,scope.$index)">编辑</el-button>
							<el-button v-if="gotype != 'sellOut'" size="small" type='primary' @click="goodsInfo(scope.row,scope.$index)">查看</el-button>
							<el-button  v-if="scope.row.is_blast != 1" size="small" type='primary' @click="setBlast(scope.row,scope.$index,1)">设为爆款</el-button>
							<el-button  v-if="scope.row.is_blast != 0" size="small" type='primary' @click="setBlast(scope.row,scope.$index,0)">取消爆款</el-button>
						  <!-- <el-button	size="small"	type="danger"	v-if="scope.row.state == 1" @click="handleDelete(scope.$index, scope.row)">删除</el-button> -->
					</template>
				</el-table-column>
				<el-table-column  v-else-if="value.type==='stock'" :key="value.id" :prop='value.prop'	:label='value.label'  width="90">
					<template #default="scope">
							<el-button size="small" type='primary' @click="handleStock(scope.row.id,scope.$index)">改库存</el-button>
					</template>
				</el-table-column>
				<!-- 订单列表 -->
				<el-table-column v-else-if="value.type==='opt'" :key="value.id" :prop='value.prop'	:label='value.label' :width="width == 1 ? 80 : width == 2 ? 310 : 160">
					<template #default="scope">
							<el-button size="small" type='primary'	v-if="scope.row.take_type == 0 && scope.row.order_status == 1" @click="handleDelivery(scope.$index, scope.row)">发货</el-button>
							<el-button size="small" type='primary'	v-if="scope.row.take_type == 0 && scope.row.order_status == 1" @click="handleRemark(scope.$index, scope.row)">添加备注</el-button>
							<el-button size="small" type='primary'	v-if="scope.row.take_type == 0 && scope.row.order_status == 1" @click="toTalk(scope.$index, scope.row)">核对地址</el-button>
							<el-button size="small" type='primary' @click="handleInfo(scope.$index, scope.row, 3)">查看</el-button>
							<el-button size="small" type='primary' v-if="scope.row.take_type == 0 && scope.row.order_status == 2" @click="seeLogistics(scope.$index, scope.row)">查看物流</el-button>
					</template>	
				</el-table-column>
				<!-- 配送方式 -->
				<el-table-column  v-else-if="value.prop==='take_type'" :key="value.id" :prop='value.prop'	:label='value.label'>
					<template #default="scope">
							<span>{{scope.row.take_type == 0 ? '快递' : scope.row.take_type == 1 ? '自提' : '上门服务'}}</span>
					</template>	
				</el-table-column>
				<!-- 下单人 -->
				<el-table-column  v-else-if="value.prop==='consignee'" :key="value.id" :prop='value.prop'	:label='value.label'>
					<template #default="scope">
							<span>{{scope.row.take_type == 1 ? scope.row.zt_consignee : scope.row.consignee}}</span>
					</template>
				</el-table-column>
				<!-- 下单手机号 -->
				<el-table-column  v-else-if="value.prop==='mobile'" :key="value.id" :prop='value.prop'	:label='value.label'>
					<template #default="scope">
							<span>{{scope.row.take_type == 1 ? scope.row.zt_mobile : scope.row.mobile}}</span>
					</template>
				</el-table-column>
				<!-- 退款售后页面操作 -->
				<el-table-column  v-else-if="value.type==='service'" :key="value.id" :prop='value.prop'	:label='value.label' >
					<template #default="scope">
							<el-button size="small" type='primary' @click="handleserviceInfo(scope.$index, scope.row)">查看</el-button>
					</template>
				</el-table-column>
				<!-- 待入账货款页面操作 -->
				<el-table-column  v-else-if="value.type==='toEntry'" :key="value.id" :prop='value.prop'	:label='value.label' >
					<template #default="scope">
							<el-button size="small" type='primary' @click="handleInfo(scope.$index, scope.row, 1)">查看</el-button>
					</template>		
				</el-table-column>
        <!-- 货款明细页面操作 -->
				<el-table-column  v-else-if="value.type==='toPaymentInfo'" :key="value.id" :prop='value.prop'	:label='value.label' >
					<template #default="scope">
							<el-button size="small" type='primary' @click="handleInfo(scope.$index, scope.row, 2)">查看</el-button>
					</template>		
				</el-table-column>
				<!-- 订单详情页图片 -->
				<el-table-column  v-else-if="value.type==='original_img' || value.type==='head_pic'" :key="value.id" :prop='value.prop'	:label='value.label'  >
					<template #default="scope">
						<el-image style="width: 100px; height: 100px" :src="value.type==='original_img' ? scope.row.original_img : scope.row.head_pic" fit='cover' />
					</template>		
				</el-table-column>
				<el-table-column  v-else-if="value.type==='orderInfoState'" :key="value.id" :prop='value.prop'	:label='value.label' >
					<template #default="scope">
            <span>{{scope.row.state == 1 ? "退款售后中" : scope.row.state == 2 ? "退款完成" : '正常'}}</span>
					</template>	
				</el-table-column>
				<!-- 导出订单列表页 -->
				<el-table-column  v-else-if="value.type==='export'" :key="value.id" :prop='value.prop'	:label='value.label' >
					<template #default="scope">
						<el-button size="small" type='primary' @click="download(scope.$index, scope.row)">下载</el-button>
					</template>		
				</el-table-column>
				<!-- 商品评价 -->
				<el-table-column  v-else-if="value.type==='reply'" :key="value.id" :prop='value.prop'	:label='value.label' >
					<template #default="scope">
						<el-button size="small" type='primary' @click="reply(scope.$index, scope.row)">回复</el-button>
					</template>		
				</el-table-column>
				<!-- 店铺动态 and 店铺分类 and 经营类目 and 社区公告-->
				<el-table-column  v-else-if="value.type==='dynamic' || value.type === 'storeSort' || value.type === 'catagory' || value.type === 'communityN' || value.type === 'ambitus'" 
				:key="value.id" :prop='value.prop'	:label='value.label' :width="value.type === 'communityN' ? 150 : (value.type === 'ambitus' && gotype != '0') ? 200 : gotype === '0' ? 260 : 100" >
					<template #default="scope">
						<el-button size="small" type='primary' @click="delDynamic(scope.$index, scope.row)">删除</el-button>
						<el-button v-if="value.type === 'communityN' || value.type === 'ambitus'" size="small" type='primary' @click="community(scope.$index, scope.row, value.type)">查看</el-button>
						<el-button v-if="value.type === 'ambitus'" size="small" type='primary' @click="editAmbitus(scope.$index, scope.row)">编辑</el-button>
						<el-button v-if="gotype === '0'" size="small" type='primary' @click="publish(scope.$index, scope.row)">发布</el-button>
					</template>		
				</el-table-column>
				<!-- 店铺公告 -->
				<el-table-column  v-else-if="value.type==='notice'" :key="value.id" :prop='value.prop'	:label='value.label' width="140" >
					<template #default="scope">
						<el-button size="small" type='primary' @click="delNotice(scope.$index, scope.row)">删除</el-button>
						<el-button size="small" type='primary' @click="editNotice(scope.$index, scope.row)">修改</el-button>
					</template>		
				</el-table-column>
				<!-- 商品评价图片 -->
				<el-table-column  v-else-if="value.type==='img' || value.type === 'store_img'" :key="value.id" :prop='value.prop'	:label='value.label'>
					<template #default="scope">
						<div v-if="value.type==='img'" style="width: 30%; display:inline-block; margin-right: 5px;" v-for="(item, i) in scope.row.img" :key="i">
							<el-image style="width: 50px; height: 50px;" :src="item" fit='cover' :preview-src-list="scope.row.img" :preview-teleported='true' :z-index="11000"/>
						</div>
						<div v-if="value.type==='store_img'" style="width: 30%; display:inline-block; margin-right: 5px;" >
							<el-image style="width: 50px; height: 50px;" :src="scope.row.img[0]" fit='cover' :preview-src-list="scope.row.img" :preview-teleported='true' :z-index="11000"/>
						</div>
					</template>		
				</el-table-column>
				<!-- 店铺分类 -->
				<el-table-column  v-else-if="value.prop==='cat_sort'" :key="value.id" :prop='value.prop'	:label='value.label' >
					<template #default="scope">
						<el-input v-model='scope.row[value.prop]' @change="sortInput(scope.row,scope.$index)"></el-input>
					</template>	
				</el-table-column>
				<!-- 经营类目 -->
				<el-table-column  v-else-if="value.prop==='casel'" :key="value.id" :prop='value.prop'	:label='value.label' >
					<template #default="scope">
						<el-tree :data="scope.row[value.prop]" :props="defaultProps"  default-expand-all/>
					</template>	
				</el-table-column>
				<el-table-column  v-else-if="value.prop==='state'" :key="value.id" :prop='value.prop'	:label='value.label' >
					<template #default="scope">
            <span>{{scope.row.state == 0 ? "审核中" : scope.row.state == 1 ? "审核成功" :"审核失败"}}</span>
					</template>	
				</el-table-column>
				<!-- 运费模板 -->
				<el-table-column  v-else-if="value.type==='freight'" :key="value.id" :prop='value.prop'	:label='value.label' >
					<template #default="scope">
						<el-button size="small" type='primary' @click="editFreight(scope.$index, scope.row)">修改</el-button>
						<el-button size="small" type='primary' @click="deleteFreight(scope.$index, scope.row)">删除</el-button>
					</template>	
				</el-table-column>
        <!-- 分享记录 -->
				<el-table-column  v-else-if="value.prop==='from_nickname'" :key="value.id" :prop='value.prop'	:label='value.label' >
          <template #default="scope">
						<span>{{scope.row.from.nickname}}</span>
					</template>	
        </el-table-column>
        <el-table-column  v-else-if="value.prop==='to_nickname'" :key="value.id" :prop='value.prop'	:label='value.label' >
          <template #default="scope">
						<span>{{scope.row.to.nickname}}</span>
					</template>	
        </el-table-column>
        <!-- 数据管理 -->
        <el-table-column  v-else-if="value.prop === 'growth_rate'" :key="value.id" :prop='value.prop'	:label='value.label' >
					<template #default="scope">
						<span class="span-two" :class="scope.row.is_add == 1 ? 'red' : scope.row.is_add == 0 ? 'green' : ''">{{scope.row.growth_rate}}</span>
					</template>		
				</el-table-column>
				<!-- 商品评价 -->
        <el-table-column  v-else-if="value.prop === 'content'" :key="value.id" :prop='value.prop'	:label='value.label'>
					<template #default="scope">
						<unfold :data="scope.row.content" :maxLen='50'></unfold>
					</template>		
				</el-table-column>
        <el-table-column  v-else-if="value.prop === 'comment_rank'" :key="value.id" :prop='value.prop'	:label='value.label'>
					<template #default="scope">
						<unfold :data="scope.row.comment_rank" :maxLen='50'></unfold>
					</template>		
				</el-table-column>
				<!-- 资源库订单 -->
				<el-table-column  v-else-if="value.prop==='original_img'" :key="value.id" :prop='value.prop'	:label='value.label'  >
					<template #default="scope">
						<el-image class="image" style="width: 100px;" :src="scope.row.original_img" fit='cover' />
					</template>		
				</el-table-column>
				<!-- 订单状态 -->
				<el-table-column  v-else-if="value.prop === 'tips'" :key="value.id" :prop='value.prop'	:label='value.label' >
					<template #default="scope">
						<!-- 供货商 -->
						<span class="span-two" style="color: red;" v-if="store == '2'">
							{{scope.row.tips == 1 ? '等待买家付款中' : scope.row.tips == 2  ? '待代销商自提' : scope.row.tips == 3 ? '待您配送' : scope.row.tips == 4 ? '待买家确认收货' 
							: scope.row.tips == 5 && scope.row.pick_up_way == 0 && (scope.row.show == 0 || scope.row.show == 1)  ? '用户申请退款中' 
							: scope.row.tips == 5 && scope.row.pick_up_way == 0 && (scope.row.show != 0 || scope.row.show != 1)  ? '买家退款中,等待用户寄件'   
							: scope.row.tips == 5 && scope.row.pick_up_way != 0 ? '买家退款中'   
							: scope.row.tips == 6  ? '退款完成' : scope.row.tips == 7  ? '订单成功' 
							: scope.row.tips == 8 ? '待您发货' : scope.row.tips == 9  ? '已收货带入帐' : scope.row.tips == 10  ? '商家驳回退款' 
							: scope.row.tips == 11  ? '平台介入' : scope.row.tips == 12  ? '平台同意' : scope.row.tips == 13  ? '平台驳回' : ''}}
						</span>
						<!-- 代销商 -->
						<span class="span-two" style="color: red;" v-if="store == '1'">
							{{ scope.row.tips == 1 ? '等待买家付款中' : scope.row.tips == 2  ? '待您自提' : scope.row.tips == 3 ? '待供货商配送' : scope.row.tips == 4 ? '等待用户确认收货' 
							: scope.row.tips == 5 && scope.row.pick_up_way == 0  ? '买家退款中' : scope.row.tips == 5 && scope.row.pick_up_way != 0  ? '用户申请退款中'
							: scope.row.tips == 6  ? '退款完成' : scope.row.tips == 7  ? '订单完成' :scope.row.tips == 8 ? '等待供货商发货'  : scope.row.tips == 9  ? '已收货带入帐' 
							: scope.row.tips == 10  ? '商家驳回退款' : scope.row.tips == 11  ? '等待平台介入' : scope.row.tips == 12  ? '平台同意退款' : scope.row.tips == 13  ? '平台驳回退款' : ''}}
						</span>
					</template>		
				</el-table-column>
				<el-table-column  v-else-if="value.type==='butResource'" :key="value.id" :prop='value.prop'	:label='value.label' :width="width" >
					<!-- 供货商 -->
					<template #default="scope" v-if="store == '2'">
						<el-button size="small" type='primary' @click="handleInfo(scope.$index, scope.row, 4)">查看</el-button>
						<el-button v-if='scope.row.tips == 8'  size="small" type='primary' @click="delivery(scope.$index, scope.row)">我要发货</el-button>
						<el-button v-if='scope.row.tips == 2'  size="small" type='primary' @click="receipt(scope.$index, scope.row,1)">自提成功</el-button>
						<el-button v-if='scope.row.tips == 3'  size="small" type='primary' @click="receipt(scope.$index, scope.row,2)">配送成功</el-button>
						<el-button v-if='scope.row.tips == 5 && scope.row.pick_up_way == 0 && scope.row.show == 1'  size="small" type='primary' @click="resourceRefund(scope.$index, scope.row, 1)">确认收货</el-button>
						<el-button v-if='scope.row.tips == 5 && scope.row.pick_up_way == 0 && scope.row.show == 0' size="small" type='primary' @click="resourceReject(scope.$index, scope.row, 2)">驳回退款</el-button>
						<el-button v-if='scope.row.tips == 5 && scope.row.pick_up_way == 0 && scope.row.show == 0' size="small" type='primary' @click="resourceRefund(scope.$index, scope.row, 2)">同意退款</el-button>
					</template>	
					<!-- 代销商 -->
					<template #default="scope" v-else>
						<el-button size="small" type='primary' @click="handleInfo(scope.$index, scope.row, 4)">查看</el-button>
						<el-button v-if='scope.row.tips == 2 || scope.row.tips == 3'  size="small" type='primary' @click="receipt(scope.$index, scope.row,3)">我已收货</el-button>
						<el-button v-if='scope.row.tips == 4 && scope.row.pick_up_way != 0'  size="small" type='primary' @click="receipt(scope.$index, scope.row,4)">用户已取货</el-button>
						<el-button v-if='scope.row.tips == 5 && scope.row.pick_up_way != 0 ' size="small" type='primary' @click="resourceReject(scope.$index, scope.row, 1)">驳回退款</el-button>
						<el-button v-if='scope.row.tips == 5 && scope.row.pick_up_way != 0' size="small" type='primary' @click="resourceRefund(scope.$index, scope.row, 3)">同意退款</el-button>
					</template>	
				</el-table-column>
				<!-- 代销状态 -->
				<el-table-column  v-else-if="value.prop === 'pick_up_way'" :key="value.id" :prop='value.prop'	:label='value.label' >
					<template #default="scope">
						<span class="span-two" style="color: red;">
							{{scope.row.pick_up_way == 1 ? '自提' : scope.row.pick_up_way == 2  ? '配送' : '快递'}}
						</span>
					</template>		
				</el-table-column>
				<el-table-column  v-else-if="value.prop === 'shop_price'" :key="value.id" :prop='value.prop'	:label='value.label' >
					<template #default="scope">
						<span class="span-two" style="color: red;">
							￥{{scope.row.shop_price}}
						</span>
					</template>		
				</el-table-column>
				<el-table-column  v-else-if="value.prop === 'market_price'" :key="value.id" :prop='value.prop'	:label='value.label' >
					<template #default="scope">
						<span style="color: #999;text-decoration: line-through;">
							￥{{scope.row.market_price}}
						</span>
					</template>		
				</el-table-column>
				<!-- 资源库商品操作 -->
				<el-table-column  v-else-if="value.prop==='store_logo'" :key="value.id" :prop='value.prop'	:label='value.label'  width="90" >
					<template #default="scope">
						<el-image class="image" style="width: 100px;" :src="scope.row.store_logo" fit='cover' />
					</template>		
				</el-table-column>
				<el-table-column  v-else-if="value.prop==='reOriginal_img'" :key="value.id" :prop='value.prop'	:label='value.label' >
					<template #default="scope">
						<el-image v-for="(item,i) in scope.row.resource_goods" :key="i" class="image" :src="item.original_img" fit='cover' />
					</template>		
				</el-table-column>
				<el-table-column  v-else-if="value.type==='toStore'" :key="value.id" :prop='value.prop'	:label='value.label' width="80">
					<template #default="scope">
							<el-button size="small" type='primary' @click="toStore(scope.$index, scope.row)">进店</el-button>
					</template>
				</el-table-column>
				<el-table-column  v-else-if="value.type==='toInfo'" :key="value.id" :prop='value.prop'	:label='value.label' >
					<template #default="scope">
							<el-button size="small" type='primary' @click="toInfo(scope.$index, scope.row,1)">查看</el-button>
					</template>
				</el-table-column>
				<el-table-column  v-else-if="value.type==='butType'" :key="value.id" :prop='value.prop'	:label='value.label' width="250">
					<template #default="scope">
						<el-button v-if="store == 3" size="small" type='primary' @click="pushGoods(scope.$index, scope.row)">重新铺货</el-button>
						<el-button v-if="store == 3" size="small" type='primary' @click="deleteGoods(scope.$index, scope.row)">删除商品</el-button>
						<el-button v-if="store == 1 || store == 2" size="small" type='primary' @click="lowerGoods(scope.$index, scope.row,2)">下架商品</el-button>
						<el-button size="small" type='primary' @click="toInfo(scope.$index, scope.row)">查看</el-button>
					</template>	
				</el-table-column>
				<!-- 店铺公告 -->
        <el-table-column  v-else-if="value.prop === 'is_show'" :key="value.id" :prop='value.prop'	:label='value.label' >
					<template #default="scope">
						<span class="span-two">{{scope.row.is_show == 1 ? '显示' : '不显示'}}</span>
					</template>		
				</el-table-column>
				<!-- 规格列表商品图 -->
				<el-table-column  v-else-if="value.type==='spec_img'" :key="value.id" :prop='value.prop'	:label='value.label'  >
					<template #default="scope">
						<el-image style="width: 100px; height: 100px" :src="scope.row.spec_img != '' ? scope.row.spec_img : scope.row.original_img" fit='cover' />
					</template>		
				</el-table-column>
				<!-- 商品排序 -->
				<el-table-column  v-else-if="value.prop==='good_sort'" :key="value.id" :prop='value.prop'	:label='value.label' >
					<template #default="scope">
						<el-input v-model='scope.row.store_sort' @change="goodSortInput(scope.row,scope.$index)"></el-input>
					</template>	
				</el-table-column>
				<!-- 黄页设置 -->
				<el-table-column  v-else-if="value.type==='yePageSet'" :key="value.id" :prop='value.prop'	:label='value.label' width="80">
					<template #default="scope">
						<el-button size="small" type='primary' @click="$parent.editDialog(true,scope.row)">编辑</el-button>
					</template>	
				</el-table-column>
				<el-table-column  v-else-if="value.type==='check'" :key="value.id" :prop='value.prop'	:label='value.label' width="80">
					<template #default="scope">
						<el-checkbox v-model="scope.row.check" @change="$parent.checkChange(scope.row,scope.$index)"></el-checkbox>
					</template>	
				</el-table-column>
				<!-- 社区公告 -->
				<el-table-column  v-else-if="value.prop==='target'" :key="value.id" :prop='value.prop'	:label='value.label'>
					<template #default="scope">
						<span class="target">{{scope.row.target == 0 ? '服务站' : scope.row.target == 1 ? '居民' : '服务站，居民'}}</span>
					</template>	
				</el-table-column>
				<!-- 社区周边 -->
				<el-table-column  v-else-if="value.type==='logo'" :key="value.id" :prop='value.prop'	:label='value.label'  width="90" >
					<template #default="scope">
						<el-image class="image" style="width: 100px;" :src="scope.row.logo" fit='cover' />
					</template>		
				</el-table-column>
				<el-table-column  v-else-if="value.type==='fix'" :key="value.id" :prop='value.prop'	:label='value.label'  width="90" >
					<template #default="scope">
						<el-button size="small" type='primary' @click="$emit('fix',scope.row)">确定</el-button>
					</template>		
				</el-table-column>
				<el-table-column  v-else-if="value.type==='store_type'" :key="value.id" :prop='value.prop'	:label='value.label'  width="90" >
					<template #default="scope">
						<span class="store_type">{{scope.row.store_ext.store_type == 1 ? '旗舰店' :scope.row.store_ext.store_type == 2 ? '专卖店' : '专营店'}}</span>
					</template>		
				</el-table-column>
				<el-table-column  v-else-if="value.type==='composite_score'" :key="value.id" :prop='value.prop'	:label='value.label'>
					<template #default="scope">
						<el-rate v-model="scope.row.composite_score" :colors="colors" disabled/>
					</template>		
				</el-table-column>
				<!-- 用户反馈列表 -->
				<el-table-column  v-else-if="value.type==='feedback_nickname' || value.type==='feedback_img'" :key="value.id" :prop='value.prop'	:label='value.label' :width="value.type==='feedback_img' ? 90 : 180">
					<template #default="scope">
						<span v-if="value.type==='feedback_nickname'">{{scope.row.user.nickname}}</span>
						<el-image v-if="value.type==='feedback_img'" class="image" style="width: 100px;" :src="scope.row.user.img" fit='cover' />
					</template>		
				</el-table-column>
				<!-- 购买记录 -->
				<el-table-column  v-else-if="value.type === 'pay_type'" :key="value.id" :prop='value.prop'	:label='value.label' >
					<template #default="scope">
						<span class="span-two">
							{{scope.row.pay_type == 1 ? '支付宝' :  '微信'}}
						</span>
					</template>		
				</el-table-column>
				<!-- 劵 -->
				<el-table-column  v-else-if="value.prop === 'send_time' || value.prop === 'send_money' || value.prop === 'send_state'" :key="value.id" :prop='value.prop'	:label='value.label' 
				:width="value.prop==='send_state' ? 140 : ''">
					<template #default="scope">
						<span class="span-two" v-if="value.prop==='send_state' && scope.row.state == 0">
							已结束
						</span>
						<el-button size="small" type='warning' v-if="value.prop==='send_state' && scope.row.state == 1" @click="$emit('endCoupon',scope.row)">结束</el-button>
						<el-popover :visible="visible" placement="top" :width="160"  v-if="value.prop==='send_state' && scope.row.state == 1">
							<!-- <p>Are you sure to delete this?</p> -->
							<div style="text-align: right; margin: 0">
								<el-button class="wdith" size="small" @click="sendType(scope.row, 0)">发放给个人</el-button>
								<el-button class="wdith" size="small" type="primary" @click="sendType(scope.row, 1)"
									>发放给星级用户</el-button
								>
							</div>
							<template #reference>
								<el-button size="small" type='warning' @click="visible = true">发放</el-button>
							</template>
						</el-popover>
						
						<span class="span-two" v-if="value.prop==='send_time'">
							{{scope.row.send_start_time}}-{{scope.row.send_end_time}}
						</span>
						<span class="span-two" v-if="value.prop==='send_money'">
							<span  style="color: #f58606;font-size: 1.1rem">￥{{scope.row.money.toFixed(2)}}</span><br/>
							<span v-if="scope.row.state == 1 && scope.row.coupon_type == 1">(满{{scope.row.condition.toFixed(2)}}元可用)</span>
						</span>
					</template>		
				</el-table-column>
				<!-- 劵 -->
				<el-table-column  v-else-if="value.type === 'selFix'" :key="value.id" :prop='value.prop'	:label='value.label' >
					<template #default="scope">
						<el-checkbox v-model="scope.row.checked" size="large"/>
					</template>		
				</el-table-column>
				<!-- 会员管理 -->
				<el-table-column  v-else-if="value.type === 'bind_label' || value.prop==='img' || value.type==='buttonType' || value.type==='nickname'"
				 :key="value.id" :prop='value.prop'	:label='value.label'  
				 :width="value.type === 'bind_label' || value.type==='nickname' ? 110 : value.type==='buttonType' ? 150 : 80"
				>
					<template #default="scope">
						<span class="span-two member" v-if="value.type === 'bind_label'" >
							{{scope.row.bind == 1 ? '绑定会员' :  '关注'}}
						</span>
						<span v-if="value.type === 'nickname'" >
							{{scope.row.store_note ? scope.row.store_note + '（' + scope.row.nickname + '）':  scope.row.nickname}}
						</span>
						<el-image v-if="value.prop==='img'" class="image" style="width: 100px;" :src="scope.row.img" fit='cover' />
						<el-button v-if="value.type==='buttonType'" size="small" type='primary' @click="handleInfo(scope.$index, scope.row, 5)">查看</el-button>
						<el-button v-if="value.type==='buttonType'" size="small" type='primary' @click="toTalk(scope.$index, scope.row,1)">发消息</el-button>
					</template>		
				</el-table-column>
				<!-- 子账号管理 -->
				<el-table-column  v-else-if="value.type === 'manageAccount'" :key="value.id" :prop='value.prop'	:label='value.label'  width="150">
					<template #default="scope">
						<el-button size="small" type='primary' @click="$emit('deleteAccount', scope.$index, scope.row)">删除</el-button>
						<el-button size="small" type='primary' @click="$emit('editAccount', 1, scope.$index, scope.row)">编辑</el-button>
					</template>		
				</el-table-column>
				<!-- 普通列表 -->
				<el-table-column  v-else  :key="value.id"	:prop='value.prop' 	:label='value.label' 	></el-table-column> 
			</template>
		
	 </el-table>
	 <!-- 分页 -->
	 <div class="demo-pagination-block" v-if="showPage">
			<el-pagination
				v-model:currentPage="currentPage"
				v-model:page-size="pageSize"
				:disabled="disabled"
				background
				layout="prev, pager, next"
				:total="total"
				@size-change="handleSizeChange"
				@current-change="handleCurrentChange"
			/>
	 </div>
	</el-card>
	 
</template>
<script>
import unfold from './unfold.vue';
import {ElMessage} from 'element-plus';
export default {
		name: "Table",
		props: {
			tableData:{ //表数据
				type:Array,
				default:[]
			},
			tableLabel:{ //表头
					type:Object,
					default:[]
			},
			showIndex:{ //是否显示序号
					type:Boolean,
					default:false
			},
			currentPage:{ //当前页
				type:Number,
				default:0
			},
			pageSize:{ //当前页条数
				type:Number,
				default:0
			},
			total:{ //数据总条数
				type:Number,
				default:0
			},
			disabled:{
					type:Boolean,
					default:false
			},
			showPage:{ //是否显示分页
					type:Boolean,
					default:true
			},
			empty: {
				type: String,
				default: '暂无数据记录~'
			},
			width: { //操作栏宽度
				type: Number,
				default: 1
			},
			store: {
				type: String,
				default: '2'
			},
			sel: {
				type: String,
				default: '2'
			},
			list: { //面包屑BreadCrumb数据
				type: Array,
				default: []
			},
			gostore: {
				type: String,
				default: '1'
			},
			goname1: {
				type: String,
				default: '1'
			},
			gotype: {
				type: String,
				default: '1'
			},
		},
		data() {
			return {
				defaultProps: {
					children: 'children',
					label: 'label',
				},
				colors: ['red','red','red'],
				visible: false,
			}
		},
		methods: {
			sendType(item, n) {
				console.log(item,n);
				this.visible = false;
				this.$emit('sendType',item, n);
			},
			handleSizeChange (e) {
				this.$emit("handleSizeChange", this.pageSize)
			},
			handleCurrentChange (e) {
				console.log(this.currentPage);
				this.$emit("handleCurrentChange", this.currentPage)
			},
			// 编辑
			goodsEdit (e,i) {
				console.log(e,i);
        this.$emit('goodsEdit', e, i)
			},
			// 查看商品详情
			goodsInfo (e,i) {
				console.log(e,i);
        this.$router.push({name: 'goodsInfo',query: {id: e.id,list: JSON.stringify(this.list), gotype: this.gotype}});
			},
			// 设为爆款-取消爆款
			setBlast (e,i,n) {
				console.log(e,i,n);
        this.$emit('setBlast', e, i, n)
			},
			// 修改库存
			handleStock (e,i) {
				console.log(e);
				this.$emit("handleStock", e, i)
			},
			// 下架
			handleLower (e,index) {
				console.log(e,index);
				this.$emit("handleLower", e, index)
			},
			// 上架
			handlePut (e,index) {
				console.log(e,index);
				this.$emit("handlePut", e,index)
			},
			// 取消下架
			cancelEvent () {
				console.log('cancel!')
			},
			// 发货
			handleDelivery(e, item) {
				// console.log(e);
				this.$emit('handleDelivery', e, item);
			},
			// 添加备注
			handleRemark(e, item) {
				// console.log(e);
				this.$emit('handleRemark', e, item)
			},
			// 跳转订单详情页
			handleInfo(e,scopes, n) {
				console.log(e,scopes);
				if(n == 1) {
					this.$router.push({name: 'orderInfo',query: {order_id: scopes.id, golink:'toEntry', goname: '待入账货款'}});
				}else if(n == 2) {
					this.$router.push({name: 'orderInfo',query: {order_id: scopes.order_id, golink:'paymentInfo', goname: '货款明细'}});
				}else if(n == 3) {
					this.$router.push({name: 'orderInfo',query: {order_id: scopes.id, golink:'order', goname: '订单管理', gotype: this.gotype, goname1: this.goname1}});
				}else if(n == 4) {
					if (this.gostore == 2 && scopes.pick_up_way == 0) {
						if (scopes.tips == 5) {
							this.$router.push({name: 'serviceInfo',query: {order_sr: scopes.order_sr, golink:'resourceOrder', goname: '资源库订单',gostore: this.gostore,  gotype: this.gotype, goname1: this.goname1}});
						}else {
							this.$router.push({name: 'orderInfo',query: {order_id: scopes.order_id, golink:'resourceOrder', goname: '资源库订单',gostore: this.gostore,  gotype: this.gotype, goname1: this.goname1}});
						}
					}else {
						this.$router.push({name: 'resourceOrderInfo',query: {order_id: scopes.order_id, golink:'resourceOrder', goname: '资源库订单',gostore: this.gostore,  gotype: this.gotype, goname1: this.goname1}});
					}
				}else if (n == 5) {
					this.$router.push({name: 'memberInfo',query: {list: JSON.stringify(this.list), user_id: scopes.user_id}});
				}
			},
			// 跳转退款售后详情页
			handleserviceInfo(e,scopes) {
				console.log(e,scopes);
				this.$router.push({name: 'serviceInfo',query: {order_sr: scopes.order_sr, golink:'serviceList', goname: '退款售后'}});
			},
			// 跳转资源库商品详情页
			toInfo(e,scopes,n) {
				console.log(e,scopes,n);
				if(this.sel == 8) {
					this.$router.push({name: 'goodInfo',query: {id: scopes.resource_goods_id, list: JSON.stringify(this.list), gotype: this.sel}});
					return
				}
				if(this.sel == 10) {
					this.$router.push({name: 'goodInfo',query: {id: scopes.id, list: JSON.stringify(this.list), gotype: this.sel}});
					return
				}
				if(n == 1){
					this.$router.push({name: 'goodInfo',query: {id: scopes.resource_goods_id, golink:'resourceGoods', goname: '首页', gotype: this.sel}});
				}else{
					this.$router.push({name: 'goodInfo',query: {id: scopes.resource_id, golink:'consignment', goname: '代销', gotype: this.store}});
				}
			},
			// 跳转资源库店铺详情页
			toStore(e,scopes,n) {
				console.log(e,scopes,n);
				console.log(this.list);
				this.$router.push({name: 'reStoreGoods',query: {id: scopes.resource_store_id, list: JSON.stringify(this.list), gotype: this.sel}});
			},
			// 下载导出文件
			download(e,scopes) {
				console.log(e,scopes);
				window.open(scopes.file);
			},
			// 回复
			reply (e,scopes) {
				this.$emit('reply', e, scopes);
			},
			// 店铺动态 ,经营类目，社区公告
			delDynamic (e,scopes) {
        // console.log(e,scopes);
				this.$emit('delDynamic', e, scopes);
			},
			// 社区周边---编辑
			editAmbitus (e,scopes) {
        // console.log(e,scopes);
				this.$emit('editAmbitus', e, scopes);
			},
			// 社区周边---发布
			publish (e,scopes) {
        // console.log(e,scopes);
				this.$emit('publish', e, scopes);
			},
			// 社区公告,社区周边---查看
			community (i,scopes,type) {
        console.log(i,scopes,type);
				if (type == 'communityN') {
					this.$router.push({name: 'comNotice',query: {id: scopes.id}})
				}else {
					this.list[1].to.query.gotype = this.gotype;
					this.$router.push({name: 'ambitusInfo',query: {id: scopes.id, list: JSON.stringify(this.list)}})
				}
			},
			// 店铺公告删除
			delNotice (e,scopes) {
        // console.log(e,scopes);
				this.$emit('delNotice', e, scopes);
			},
			// 店铺公告编辑
			editNotice (e,scopes) {
        // console.log(e,scopes);
				this.$emit('editNotice', e, scopes);
			},
			// 店铺分类排序
			sortInput(item,i) {
				console.log(item);
				this.tableData.map((item,i) =>{
					console.log(item.cat_sort);
				})
				this.$emit('sortInput', this.tableData);
			},
			// 商品排序
			goodSortInput(item,i) {
				console.log(item,i);
				// return
				let that = this;
				let data = {
					goods_id: item.id,
					sort: parseInt(item.store_sort)
				}
				that.$request.post('/goods/save_goods_sort',data).then(res => {
					console.log(res);
					ElMessage({
						type: 'success',
						message: res.msg
					})
					that.$parent.getTableData();
				}).catch(error => {
          console.log(error);
        })
			},
			// 编辑运费模板
			editFreight(e,i) {
        console.log(e,i);
        this.$emit('editFreight', e, i);
      },
			// 删除运费模板
			deleteFreight(e, scopes) {
        console.log(e, scopes);
        this.$emit('dynamic', e, scopes);
      },
			// 查看物流
			seeLogistics(e, scopes) {
        console.log(e, scopes);
        this.$emit('seeLogistics', e, scopes);
      },
			// 核对地址
			toTalk(e, scopes, n) {
				let login = JSON.parse(sessionStorage.getItem('login'));
				if(login.is_service == 0) {
					ElMessage({
						type: 'warning',
						message: '当前账号没有客服权限'
					})
					return
				}
        console.log(e, scopes);
				if (n == 1) {
					let user = {
						name:`${this.$store.state.JIMVersion}_${scopes.user_id}`,
						nickName: scopes.nickname,
						headimage: scopes.img
					}
					this.$router.push({name: 'talk',query: {id: scopes.user_id}, user: JSON.stringify(user)})
				}else {
					let user = {
						name:`${this.$store.state.JIMVersion}_${scopes.user.id}`,
						nickName: scopes.user.nickname,
						headimage: scopes.user.img
					}
					this.$request.get('/order/address',{
						params: {
							order_id: scopes.id
						}
					}).then(res => {
						console.log(res.data);
						this.$router.push({name: 'talk', query:{ id: scopes.user.id, address: JSON.stringify(res.data), goods: JSON.stringify(scopes.order_goods), user: JSON.stringify(user) }});
					}).catch( error => {
						console.error(error);
					})
				}
				
				
      },
			// 重新铺货
			pushGoods (index,e) {
				console.log(index, e);
				this.$emit("pushGoods", index, e)
			},
			// 删除商品
			deleteGoods (index,e) {
				console.log(index, e);
				this.$emit("deleteGoods", index, e)
			},
			// 资源库同意退款
			resourceRefund (index,e) {
				console.log(index, e);
				this.$emit("resourceRefund", index, e)
			},
			// 资源库驳回退款
			resourceReject (index,e, n) {
				console.log(index, e);
				this.$emit("resourceReject", index, e, n)
			},
			// 资源库我要发货
			delivery (index,e) {
				console.log(index, e);
				this.$emit("delivery", index, e)
			},
			// 资源库我已收货，配送成功，自提成功，用户已取货
			receipt (index,e,n) {
				console.log(index, e, n);
				this.$emit("receipt", index, e, n)
			},
			// 资源库下架商品
			lowerGoods (index,e) {
				console.log(index, e);
				this.$emit("lowerGoods", index, e)
			},
		},
		//计算属性 类似于data概念
		computed: {
			
		},
		//这里存放数据
		components: {
			unfold
		},
};


 </script>
 <style scoped lang='scss'>
  .demo-pagination-block{
    margin-top: 20px;
    text-align: center;
  }
  .el-pagination {
    margin-top: 10px;
    display: flex;
    justify-content: center;
  }
  .red{
    color: red;
  }
  .green {
    color: #67c23a;
  }
	.image {
		:deep(.el-image__inner) {
			width: 3rem;
			height: 3rem;
		}
	}
	.target {
		background-color: #f9de59;
		padding: 0.5rem 0.8rem;
		color: #fff;
		border-radius: 1rem;
	}
	.store_type {
		background-color: red;
		color: #fff;
		padding: 0.5rem;
		border-radius: 1rem;
	}
	.member {
		color: #f9de59;
		background: #3c1c01;
		padding: 0.5rem;
		border-top-left-radius: 50%;
		border-bottom-right-radius: 50%;
	}
	.wdith {
		width: 8rem;
		margin: 0 0.5rem 12px;
	}
 </style>