import request from './request.js'
import axios from 'axios';

export function getUploadToken(e) {
	return new Promise((resolve, reject) => {
		let mobile = JSON.parse(sessionStorage.getItem('storeInfo')).mobile;
		// 获取毫秒时间戳
		var newDateTime = (new Date()).valueOf();
		let time = `${newDateTime}${mobile}`;
		let type = 2;
		if (e == 1) {
			type = 1;
		}
		request.post('/upload/upload_token',{
			file_name: time,
			type
		}).then((res) => {
			// console.log(res);
      // 把获取token的key值一起返回
      res.key = time;
      resolve(res)
		}).catch((error) => {
			console.error(error);
			return Promise.reject(error)
		});
	})
	
	
}

//时间转换
function transformTimestamp(timestamp,n){
	let a = new Date(timestamp).getTime();
	const date = new Date(a);
	const Y = date.getFullYear() + '-';
	const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
	const D = (date.getDate() < 10 ? '0'+date.getDate() : date.getDate()) + '  ';
	const h = (date.getHours() < 10 ? '0'+date.getHours() : date.getHours()) + ':';
	const m = (date.getMinutes() <10 ? '0'+date.getMinutes() : date.getMinutes()) + ':' ;
	const s = (date.getSeconds() < 10 ? '0'+date.getSeconds() : date.getSeconds()); // 秒
	const dateString = Y + M + D + h + m;
	const dateString1 = Y + M + D;
	const dateString2 = Y + M + D + h + m + s;
	if(n == 1) {
		return dateString1;
	}else if(n == 2){
		// console.log('dateString', dateString); // > dateString 2021-07-06 14:23
		return dateString2;
	}else {
		// console.log('dateString', dateString); // > dateString 2021-07-06 14:23
		return dateString;
	}
}



export {
	// getUploadToken,
	transformTimestamp
}